<template>
  <div class="applyProductCombo-container">
      <div class="header2 update_win">
        <van-nav-bar title="提报套餐信息" left-text="返回列表" left-arrow @click-left="onClickLeft"></van-nav-bar>
      </div>
    <div class="comboList">
      <van-form validate-first class="updateForm_cs1">
        <van-cell-group>
          <van-cell title="套餐名称:">
            <template #title>
              <div style="width: 70px;">套餐名称: <span style="color: red; font-size: 16px;">*</span></div> 
            </template>
            <div>
              <van-field v-model="forms.comboName" maxlength="30" placeholder="请输入套餐名称" />
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="市场原价:">
            <template #title>
              <div style="width: 70px;">市场原价: <span style="color: red; font-size: 16px;">*</span></div> 
            </template>
            <div>
              <van-field v-model="forms.original" class="price" type="Number" maxlength="8" placeholder="请输入市场原价" >
                <template #button>元</template>
              </van-field>
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="全网最低:">
            <template #title>
              <div style="width: 70px;">全网最低: <span style="color: red; font-size: 16px;">*</span></div> 
            </template>
            <div>
              <van-field v-model="forms.floorPrice" class="price" type="Number" maxlength="8" placeholder="请输入全网最低">
                  <template #button>元</template>
              </van-field>
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="拟定售价:">
            <template #title>
              <div style="width: 70px;">拟定售价: <span style="color: red; font-size: 16px;">*</span></div> 
            </template>
            <div>
              <van-field v-model="forms.sellingPrice" class="price" type="Number" maxlength="8" placeholder="请输入拟定售价">
                <template #button>元</template>
              </van-field>
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="供货结算价:">
            <template #title>
              <div style="width: 84px;">供货结算价: <span style="color: red; font-size: 16px;">*</span></div> 
            </template>
            <div>
              <van-field v-model="forms.closePrice" class="price" type="Number" maxlength="8" placeholder="请输入供货结算价">
                <template #button>元</template>
              </van-field>
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="常备库存:">
            <template #title>
              <div style="width: 70px;">常备库存: <span style="color: red; font-size: 16px;">*</span></div>
            </template>
            <van-field v-model="forms.inventory" type="Number" maxlength="8" placeholder="请输入常备库存" />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="渠道控价:">
            <template #title>
              <div style="width: 70px; text-align: left;">渠道控价:</div>
            </template>
            <van-field v-model="forms.ditchControlPrice" type="Number" maxlength="8" placeholder="请输入渠道控价" />
          </van-cell>
        </van-cell-group>
        <div class="comboBtn">
        </div>
      </van-form>
      <van-tabbar v-model="active">
        <div style="width: 100%; text-align: right;" v-if="setDisabled">
          <van-button type="primary" @click="submitData()" style="margin: 5px; border-radius: 5px; padding: 3px 32px;">提&nbsp;&nbsp;&nbsp;交</van-button>
        </div>
        <div style="width: 100%; text-align: right;" v-else>
          <van-button type="primary" disabled  style="margin: 5px; border-radius: 5px; padding: 3px 32px;" >提&nbsp;&nbsp;&nbsp;交</van-button>
        </div>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
export default {
  data() {
    return {
      active: 0,
      setDisabled: true,
      forms: {
        comboName: '',
        original: '',
        floorPrice: '',
        sellingPrice: '',
        closePrice: '',
        inventory: '',
        ditchControlPrice: 0
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        path: '/productAudit',
        query: {}
      })
    },
    submitData() {
      if(this.forms.comboName == ''){
        Notify({ type: 'danger', message: '套餐名称不能为空!!!' })
        return false;
      }
      if(this.forms.original == ''){
        Notify({ type: 'danger', message: '市场原价不能为空!!!' })
        return false;
      }
      if(this.forms.floorPrice == ''){
        Notify({ type: 'danger', message: '全网最低价不能为空!!!' })
        return false;
      }
      if(Number(this.forms.original) < Number(this.forms.floorPrice)){
        Notify({ type: 'danger', message: '市场原价不能低于全网最低价!!!' })
        return false;
      }
      if(this.forms.sellingPrice == ''){
        Notify({ type: 'danger', message: '拟定售价不能为空!!!' })
        return false;
      }
      if(Number(this.forms.original) < Number(this.forms.sellingPrice)){
        Notify({ type: 'danger', message: '市场原价不能低于拟定售价!!!' })
        return false;
      }
      if(this.forms.closePrice == ''){
        Notify({ type: 'danger', message: '供货结算价不能为空!!!' })
        return false;
      }
      if(Number(this.forms.original) < Number(this.forms.closePrice)){
        Notify({ type: 'danger', message: '市场原价不能低于供货结算价!!!' })
        return false;
      }
      if(Number(this.forms.sellingPrice) < Number(this.forms.closePrice)){
        Notify({ type: 'danger', message: '拟定售价不能低于供货结算价!!!' })
        return false;
      }
      if(this.forms.inventory == ''){
        Notify({ type: 'danger', message: '常备库存不能为空!!!' })
        return false;
      }
      // if (this.forms.comboName == '' || this.forms.original == '' || this.forms.floorPrice == '' || this.forms.sellingPrice == '' || this.forms.closePrice == '' || this.forms.inventory == '') {
      //   Notify({ type: 'danger', message: '请检查填写的数据是否完整' })
      // } else if (Number(this.forms.original) < Number(this.forms.floorPrice)) {
      //   Notify({ type: 'danger', message: '市场原价低于全网最低价了' })
      // } else if (Number(this.forms.original) < Number(this.forms.closePrice)) {
      //   Notify({ type: 'danger', message: '市场原价低于供货结算价了' })
      // } else if (Number(this.forms.original) < Number(this.forms.sellingPrice)) {
      //   Notify({ type: 'danger', message: '市场原价低于拟定售价了' })
      // } else if (Number(this.forms.sellingPrice) < Number(this.forms.closePrice)) {
      //   Notify({ type: 'danger', message: '拟定售价低于供货结算价了' })
      // } else {
      this.setDisabled = false
      this.$http
        .ajax(
          '/ffkj-main/merchantProductSpecs/create',
          {
            productId: this.$route.query.ids,
            specsName: this.forms.comboName, 
            originalPrice: Number(this.forms.original),
            sellingPrice: Number(this.forms.sellingPrice),
            minSellingPrice: Number(this.forms.floorPrice),
            agreementStock: Number(this.forms.inventory),
            settlementPrice: Number(this.forms.closePrice),
            controlPrice: Number(this.forms.ditchControlPrice)
          },
          'post',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            Notify({ type: 'success', message: '创建成功', duration: 500 })
            setTimeout(
              function () {
                this.$router.push({
                  path: '/auditSpecificationMessage',
                  query: {
                    id1: this.$route.query.ids
                  }
                })
              }.bind(this),
              500
            )
          } else {
            Notify({ type: 'danger', message: res.message })
          }
        })
    },
  }
}
</script>
<style scoped>
</style>